import { FC } from 'react';
import { Box } from '@mui/material';
import {
    InputContent
} from 'components/advertisementsFilter/components/common/multiInput/partials/content/Content';
import {
    ExtendedFilterButton
} from 'components/advertisementsFilter/components/common/multiInput/partials/extendedFilterButton/ExtendedFilterButton';
import {
    SearchButton
} from 'components/advertisementsFilter/components/common/searchButton/SearchButton';

interface IMultiInput {
    height?: number;
    location?: string;
    category?: string;
    transaction?: string;
    isWithBackground?: boolean;
    searchButtonSmall?: boolean;
    isWithSearchButton?: boolean;
    onSearchButtonClick?: () => void;
    onExtendedButtonClick?: () => void;
    isWithExtendedFilterButton?: boolean;
}

export const MultiInput: FC<IMultiInput> = ({
    height,
    location,
    category,
    transaction,
    isWithBackground,
    searchButtonSmall,
    isWithSearchButton,
    onSearchButtonClick,
    onExtendedButtonClick,
    isWithExtendedFilterButton,
}) => {
    return <Box
        gap={1}
        width='100%'
        display='flex'
        paddingY={1.5}
        height={height}
        paddingLeft={3}
        overflow='hidden'
        paddingRight={1.25}
        alignItems='center'
        borderRadius='99px'
        boxShadow='0px 4px 16px 0px rgba(0, 0, 0, 0.08)'
        border={(theme) => `1px solid ${theme.palette.dividerTransparent}`}
        bgcolor={(theme) => isWithBackground && theme.palette.backgroundPrimary.main}
        justifyContent={(isWithSearchButton || isWithExtendedFilterButton) && 'space-between'}
    >
        <Box
            flex={1}
            gap={1.5}
            display='flex'
            overflow='hidden'
            alignItems='center'
            onClick={onExtendedButtonClick}
        >
            <InputContent
                location={location}
                category={category}
                transaction={transaction}
            />
        </Box>

        <Box
            gap={0.8}
            display='flex'
            overflow='hidden'
            alignItems='center'
        >
            {isWithExtendedFilterButton &&
                <ExtendedFilterButton onClick={onExtendedButtonClick}/>
            }

            {isWithSearchButton &&
                <SearchButton
                    isMobile
                    onClick={onSearchButtonClick}
                    small={searchButtonSmall}
                />
            }
        </Box>
    </Box>;
};
