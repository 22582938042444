import React, { FC } from 'react';
import { Stack, useTheme } from '@mui/material';
import { Wrapper } from 'components/layout/header/components/navbar/common/links/addAdvertisement/common/wrapper/Wrapper';
import { AddAdvertisementIcon } from 'components/layout/header/components/navbar/common/links/addAdvertisement/common/icon/AddAdvertisementIcon';

interface IAddAdvertiser {
    href: string;
}

export const AddAdvertiser: FC<IAddAdvertiser> = ({ href }) => {
    const { palette } = useTheme();

    return (
        <>
            <Wrapper href={href}>
                <Stack
                    p={0.5}
                    borderRadius={10}
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                        '&:active': {
                            bgcolor: palette.backgroundSecondary.main,
                        },
                    }}
                >
                    <AddAdvertisementIcon />
                </Stack>
            </Wrapper>
        </>
    );
};
