import { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';

interface IDesktopHeader {
    onCloseFilter: () => void;
}

export const DialogHeader: FC<IDesktopHeader> = ({ onCloseFilter }) => {
    const theme = useTheme();

    return <Box
        top={0}
        zIndex={2}
        width='100%'
        display='flex'
        position='sticky'
        alignItems='center'
        mb={{ xs: 3 }}
        justifyContent='center'
        padding={{ xs: 1.5, md: 3 }}
        borderBottom={{ md: `1px solid ${theme.palette.dividerTransparent}` }}
        boxShadow={{ xs: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)', md: 'none' }}
        bgcolor={(theme) => theme.palette.backgroundPrimary.main}
    >
        <Box
            top='50%'
            left={24}
            display='flex'
            alignItems='center'
            position='absolute'
            justifyContent='center'
            onClick={onCloseFilter}
            sx={{
                cursor: 'pointer',
                transform: 'translate(0, -50%)'
            }}
        >
            <Svg icon component={CloseIcon} width={24}/>
        </Box>

        <Text
            semibold
            variant='label1'
            mobileVariant='body2'
        >
            Hľadať nehnuteľnosti
        </Text>
    </Box>;
};