import { FC } from 'react';
import { Box } from '@mui/material';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/modules/location/interfaces/IAutocompleteOption';
import {
    Option
} from 'components/advertisementsFilter/modules/location/components/common/autocompletes/previouslySearched/partials/options/option/Option';

interface ILocationOptions {
    withXPadding?: boolean;
    isLastSearched?: boolean;
    options: {
        id: string;
        locationOptions: IAutocompleteOption[]
    }[];
    onChangeLocation: (locations: IAutocompleteOption[]) => void;
}
export const LocationOptions: FC<ILocationOptions> = ({
    options,
    withXPadding,
    isLastSearched,
    onChangeLocation
}) => {

    return <Box
        width={{ xs: '100vw', md: 'auto' }}
        display='flex'
        flexDirection='column'
        height={{
            xs: !isLastSearched ? 'calc(100vh - 136px)' : 'auto',
            md: 'initial'
        }}
        overflow={{ xs: 'auto', md: 'visible' }}
        mx={-3}
        px={3}
        pb={{
            xs: !isLastSearched ? 15 : 0,
            md: 0
        }}
    >
        {options.map((option, index) => <Option
            key={index}
            withXPadding={withXPadding}
            locationOptions={option.locationOptions}
            onClick={() => onChangeLocation(option.locationOptions)}
        />)}
    </Box>;
};
