import { FC } from 'react';
import { Box } from '@mui/material';
import {
    TransactionInput
} from 'components/advertisementsFilter/modules/transaction/components/common/input/TransactionInput';
import {
    CompleteBox
} from 'components/advertisementsFilter/modules/transaction/components/common/completeBox/CompleteBox';
import { Popover } from 'components/advertisementsFilter/modules/common/components/popover/Popover';
import { EInputs } from 'components/advertisementsFilter/modules/common/enums/EInputs';
import { useTransactionOptions } from 'components/advertisementsFilter/modules/transaction/hooks/useTransactionOptions';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';

interface ITransaction {
    isInFocus: boolean;
    isHovered: boolean;
    isPopoverOpen: boolean;
    onClosePopover: () => void;
    popoverAnchorEl: HTMLElement;
    onChangeHoverState: (flag: EInputs) => void;
    onSetInputInFocus: (value: EInputs) => void;
    onOpenPopover: (target: HTMLElement) => void;
    onChangeTransaction: (transactionId: number) => void;
}

export const Transaction: FC<ITransaction> = ({
    isInFocus,
    isHovered,
    isPopoverOpen,
    onOpenPopover,
    onClosePopover,
    popoverAnchorEl,
    onSetInputInFocus,
    onChangeHoverState,
    onChangeTransaction,
}) => {
    const selectedTransaction = useTransactionStore((store) => store.selectedTransaction);
    const transactionOptions = useTransactionStore((store) => store.transactionOptions);

    const {
        selectedOptionTitle
    } = useTransactionOptions(transactionOptions, selectedTransaction);

    return <>
        <TransactionInput
            label='Ponuka'
            isHovered={isHovered}
            isInFocus={isInFocus}
            clearButtonMargin={14}
            onClick={onOpenPopover}
            placeholder='Všetko'
            value={selectedOptionTitle}
            onSetInputInFocus={onSetInputInFocus}
            onChangeHoverState={onChangeHoverState}
            onChangeTransaction={onChangeTransaction}
        />

        <Popover
            isOpen={isPopoverOpen}
            onClose={() => {
                onClosePopover();
                onSetInputInFocus(null);
            }}
            anchorEl={popoverAnchorEl}
            offsetOptions={{ x: 65, y: 23 }}
        >
            <Box
                paddingY={2}
            >
                <CompleteBox
                    options={transactionOptions}
                    transaction={selectedTransaction?.id}
                    onTransactionChange={(value) => {
                        const newValue = selectedTransaction && selectedTransaction.id === value
                            ? null : value;

                        onChangeTransaction(newValue);
                        onClosePopover();
                    }}
                />
            </Box>
        </Popover>
    </>;
};
