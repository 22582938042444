import { FC } from 'react';
import { Box, LinearProgress } from '@mui/material';

export const Loading: FC = () => {
    return (
        <Box position='absolute' width='100%' top={0} left={0}>
            <LinearProgress color='primary' />
        </Box>
    );
};
