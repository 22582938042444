import { FC } from 'react';
import { Box, Grid2 } from '@mui/material';
import {
    Title
} from 'components/advertisementsFilter/modules/extended/components/title/Title';
import {
    AdvertisementsItem
} from 'components/advertisementsFilter/modules/extended/components/advertisements/partials/item/Item';
import {
    ContentItemWrapper
} from 'components/advertisementsFilter/modules/extended/components/common/contentItem/wrapper/ContentItemWrapper';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';

export const Advertisements: FC = () => {
    const isOnlyWithVideo = useExtendedStore((store) => store.isOnlyWithVideo);
    const isOnlyNewBuilding = useExtendedStore((store) => store.isOnlyNewBuilding);
    const setIsOnlyWithVideo = useExtendedStore((store) => store.setIsOnlyWithVideo);
    const setIsOnlyNewBuilding = useExtendedStore((store) => store.setIsOnlyNewBuilding);

    return <ContentItemWrapper>
        <Box mb={1}>
            <Title text='Ponuka inzerátov'/>
        </Box>

        <Grid2
            container
            spacing={1}
        >
            <Grid2 component='div' size={{ xs: 12, sm: 6 }}>
                <AdvertisementsItem
                    title='Iba novostavby'
                    onChange={setIsOnlyNewBuilding}
                    isChecked={isOnlyNewBuilding}
                />
            </Grid2>
            <Grid2 component='div' size={{ xs: 12, sm: 6 }}>
                <AdvertisementsItem
                    title='Iba s video prehliadkou'
                    onChange={setIsOnlyWithVideo}
                    isChecked={isOnlyWithVideo}
                />
            </Grid2>
        </Grid2>
    </ContentItemWrapper>;
};
