import { FC } from 'react';
import Link from 'components/link/Link';
import { Svg } from 'modules/theme/components/svg/Svg';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import BrandIconSvg from 'components/advertisementsFilter/assets/icons/RealEstateIcon.svg';

export const BrandIcon: FC = () => {
    return (
        <Link
            sx={{ display: 'flex' }}
            ariaLabel='brand icon'
            href={`${AppConfigService.getNehnutelnostiUrl()}`}
        >
            <Svg icon component={BrandIconSvg} width={34} />
        </Link>
    );
};
