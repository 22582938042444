'use client';

import { FC } from 'react';
import Script from 'next/script';
import { AdsPag } from 'components/ads/enums/AdsPag';
import useCookieBar from 'modules/stores/cookieBar/useCookieBar';
import { IUseAdsSecParams } from 'components/page/advertisement/listing/hooks/ads/useAdsSecParams';

declare global {
    interface Window {
        updateAdsTargeting: () => void;
    }
}

interface AdsHeadProps {
    headScript: string;
    adsPag: AdsPag;
    adsSec?: IUseAdsSecParams | string;
}

export const AdsHead: FC<AdsHeadProps> = ({ headScript, adsPag, adsSec }) => {

    const { isAllowed } = useCookieBar();

    if (!headScript || !adsPag || !isAllowed.googleDoubleClick) {
        return null;
    }

    return (
        <Script
            strategy='afterInteractive'
            id='ads-head-script'
            dangerouslySetInnerHTML={{
                __html: `var adsPag = ["${adsPag}"];\nvar adsSec = ${JSON.stringify(
                    adsSec ? Object.values(adsSec)?.filter(i => i) : null
                )};
                ${headScript}`,
            }}
        />
    );
};
