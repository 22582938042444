import { useCallback } from 'react';
import { useCookieBarStore, IIsAllowed } from 'modules/stores/cookieBar/useCookieBarStore';

interface IReturn {
    isAllowed: IIsAllowed;
    allow: (key: keyof IIsAllowed) => void;
}

const useCookieBar = (): IReturn => {
    const isAllowed = useCookieBarStore((store) => store.isAllowed);
    const setIsAllowed = useCookieBarStore((store) => store.setIsAllowed);

    const allow = useCallback((key: keyof IIsAllowed) => {
        setIsAllowed(key, true);
    }, [setIsAllowed]);

    return {
        isAllowed,
        allow
    };
};

export default useCookieBar;