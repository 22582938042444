import { FC } from 'react';
import { Box } from '@mui/material';
import { LSOption } from 'components/advertisementsFilter/modules/location/types/LSOption';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import {
    Option
} from 'components/advertisementsFilter/modules/location/components/common/selectedLocations/partials/option/Option';
import { useLocationInputStore } from 'components/advertisementsFilter/modules/location/store/useLocationInputStore';
import {
    SelectLocationsHint
} from 'components/advertisementsFilter/modules/location/components/common/completeBox/partials/selectHint/SelectLocationsHint';

interface ISelectedLocations {
    isExtended?: boolean;
    locationQuery: string;
    previouslySearched: LSOption[];
}

export const SelectedLocations: FC<ISelectedLocations> = ({
    isExtended,
    locationQuery,
    previouslySearched,
}) => {
    const selectedLocations = useLocationStore((store) => store.selectedLocations);
    const onDeselectLocation = useLocationStore((store) => store.onDeselectLocation);
    const locationInputRef = useLocationInputStore((store) => store.locationInputRef);

    const showLocationsHint = previouslySearched &&
        previouslySearched.length > 0 &&
        selectedLocations.length === 1 &&
        locationQuery.length === 0;

    return <Box
        gap={1}
        display='flex'
        flexWrap='wrap'
        flexDirection='column'
    >
        <Box display='flex' gap={1} flexWrap='wrap'>
            {selectedLocations.map((location) =>
                <Option
                    key={location.id}
                    location={location}
                    onClick={() => {
                        onDeselectLocation(location.id);

                        if (locationInputRef && locationInputRef.current) {
                            locationInputRef.current.focus();
                        }
                    }}
                />
            )}
        </Box>

        {showLocationsHint &&
            <Box my={{ md: isExtended ? 1 : 1.5 }}>
                <SelectLocationsHint/>
            </Box>
        }
    </Box>;
};