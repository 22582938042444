enum ESeoCampain {
    CHEAP = 'cheap'
}

export const cheapSeoCampain = {
    name: ESeoCampain.CHEAP,
    pathMatcher: 'lacne',
    seoTitlePrefix: 'Lacné',
    seoH1Postfix: 'lacné',
};

export const cheapSeoCampainCategories = [
    'APARTMENTS',
    'SECONDARY_HOUSES',
    'SECONDARY_LANDS',
];

export const districtCityIds = [
    '100012526', // Bánovce nad Bebravou
    '100010679', // Banská Bystrica
    '100011343', // Banská Štiavnica
    '100011538', // Bardejov
    '0_i9128BtwC-IGpZQrnK', // BA
    '100010685', // Brezno
    '100011405', // Bytča
    '100010744', // Čadca
    '100011470', // Detva
    '100010780', // Dolný Kubín
    '100010113', // Dunajská Streda
    '100010306', // Galanta
    '100012232', // Gelnica
    '100010578', // Hlohovec
    '100011633', // Humenné
    '100011065', // Ilava
    '100011963', // Kežmarok
    '100010075', // Komárno
    'DnC4128B1D1qP4jjZK1f', // KE
    '100011497', // Krupina
    '100010754', // Kysucké Nové Mesto
    '100010166', // Levice
    '100012571', // Levoča
    '100010841', // Liptovský Mikuláš
    '100010916', // Lučenec
    '100010651', // Malacky
    '100010984', // Martin
    '100011676', // Medzilaborce
    '100011835', // Michalovce
    '100010390', // Myjava
    '100010805', // Námestovo
    '100010001', // Nitra
    '100010530', // Nové Mesto nad Váhom
    '100010248', // Nové Zámky
    '100010446', // Partizánske
    '100010659', // Pezinok
    '100010606', // Piešťany
    '100010962', // Poltár
    '100011944', // Poprad
    '100011051', // Považská Bystrica
    '100012010', // Prešov
    '100011099', // Prievidza
    '100011085', // Púchov
    '100012197', // Revúca
    '100011149', // Rimavská Sobota
    '100012139', // Rožňava
    '100010897', // Ružomberok
    '100012104', // Sabinov
    '100010663', // Senec
    '100010356', // Senica
    '100010410', // Skalica
    '100011706', // Snina
    '100011914', // Sobrance
    '100012217', // Spišská Nová Ves
    '100012247', // Stará Ľubovňa
    '100012365', // Stropkov
    '100012291', // Svidník
    '100010338', // Šaľa
    '100010426', // Topoľčany
    '100012390', // Trebišov
    '100010488', // Trenčín
    '100010555', // Trnava
    '100011043', // Turčianske Teplice
    '100010829', // Tvrdošín
    '100011271', // Veľký Krtíš
    '100012645', // Vranov nad Topľou
    '100010070', // Zlaté Moravce
    '100011460', // Zvolen
    '100011401', // Žarnovica
    '100011339', // Žiar nad Hronom
    '100011403', // Žilina
];