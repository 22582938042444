import { useCallback } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { IGtmEvent } from 'modules/analytics/IAnalytics';
import useLoading from 'modules/stores/loading/useLoading';
import { useLoginStore } from 'modules/stores/login/useLoginStore';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';
import { cheapSeoCampain } from 'modules/advertisementsFilter/query/constants/seo';
import { ELocationType } from 'modules/api/clients/location/enums/type/ELocationType';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { getSearchEvent } from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';
import { getResetEcommerceEvent } from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import { useSearchStore } from 'components/advertisementsFilter/features/search/store/useSearchStore';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';
import { IAutocompleteOption } from 'components/advertisementsFilter/modules/location/interfaces/IAutocompleteOption';
import { usePreviouslySearchedLocations } from 'components/advertisementsFilter/modules/location/hooks/usePreviouslySearchedLocations';

interface IReturn {
    onRedirectByFilter: () => void;
    onOrderChangeAndRedirect: (order: EOrder) => void;
}

export const useRedirectByFilter = (): IReturn => {
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();
    const { start: startLoading } = useLoading();

    const router = useRouter();
    const pathname = usePathname();

    const isUserLoggedIn = useLoginStore((store) => store.isUserLoggedIn);

    const { addLocalStorageOption } = usePreviouslySearchedLocations();

    const redirectUrl = useSearchStore((store) => store.redirectUrl);

    const extendedParams = useExtendedStore((store) => store);
    const currentOrder = useSearchStore((store) => store.currentOrder);
    const setPriceFrom = useExtendedStore((store) => store.setPriceFrom);
    const selectedLocations = useLocationStore((store) => store.selectedLocations);
    const selectedCategories = useCategoriesStore((store) => store.selectedCategories);
    const selectedTransaction = useTransactionStore((store) => store.selectedTransaction);

    const onComparePathsAndRedirect = useCallback((redirectUrlTo: URL): void => {
        const redirectPathname = redirectUrlTo.pathname;

        const isCheapSeoCampain = pathname.includes(
            cheapSeoCampain.pathMatcher,
        );

        const searchParamsOmitted = redirectUrlTo.searchParams;
        if (isCheapSeoCampain) {
            searchParamsOmitted.delete('priceFrom');
            searchParamsOmitted.delete('order');

            setPriceFrom(0);
        }

        const redirectParams = Array.from(searchParamsOmitted).length > 0
            ? `?${searchParamsOmitted.toString()}`
            : '';

        const finalRedirectUrl = `${redirectPathname}${redirectParams}`;
        const currentUrl = window.location.pathname + window.location.search;

        if (currentUrl !== finalRedirectUrl) {
            startLoading();
        }

        router.push(finalRedirectUrl);
    }, [startLoading, router, pathname, setPriceFrom]);

    const onOrderChangeAndRedirect = (order: EOrder): void => {
        if (!redirectUrl) return;

        const redirectUrlLocal = redirectUrl;
        if (!!redirectUrlLocal.searchParams.get('order')) {
            redirectUrlLocal.searchParams.delete('order');
        }

        if (order && order !== EOrder.DEFAULT) {
            redirectUrlLocal.searchParams.append('order', order);
        }

        onComparePathsAndRedirect(redirectUrlLocal);
    };

    const onRedirectByFilter = useCallback(async () => {
        const isHomePage = pathname === '/';

        if (selectedLocations.length) {
            const getSubName = (location: ILocation): string =>
                location.type === ELocationType.CITY && location.parent?.name;

            const mapLocationToAutocompleteOption = (
                location: ILocation
            ): IAutocompleteOption => ({
                id: location.id,
                type: location.type,
                sefName: location.sefName,
                subName: getSubName(location),
                name: location.name,
                parent: location?.parent
                    ? mapLocationToAutocompleteOption(location?.parent as ILocation)
                    : undefined,
            });


            addLocalStorageOption(
                selectedLocations.map(
                    (location) => mapLocationToAutocompleteOption(location))
            );
        }

        const events: IGtmEvent[] = [
            getResetEcommerceEvent(),
            getSearchEvent(
                {
                    categories: selectedCategories.map((category) => category.name),
                    locations: selectedLocations.map((location) => location.id),
                    onlyNewbuilding: extendedParams.isOnlyNewBuilding,
                    onlyWithVideo: extendedParams.isOnlyWithVideo,
                    transaction: selectedTransaction?.name,
                    priceFrom: extendedParams.prices.from,
                    areaFrom: extendedParams.area.from,
                    fulltext: extendedParams.fulltext,
                    priceTo: extendedParams.prices.to,
                    areaTo: extendedParams.area.to,
                    order: currentOrder,
                },
                selectedLocations.map((location) => location.sefName),
                selectedTransaction?.id,
                isHomePage,
                isUserLoggedIn
            ),
        ];

        gtmSendEvent(...events);

        onComparePathsAndRedirect(redirectUrl);
    }, [
        pathname,
        redirectUrl,
        currentOrder,
        gtmSendEvent,
        isUserLoggedIn,
        extendedParams,
        selectedLocations,
        selectedCategories,
        selectedTransaction,
        addLocalStorageOption,
        onComparePathsAndRedirect,
    ]);

    return {
        onRedirectByFilter,
        onOrderChangeAndRedirect,
    };
};
