import { useCallback } from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/modules/location/interfaces/IAutocompleteOption';
import { LSOption } from 'components/advertisementsFilter/modules/location/types/LSOption';
import { useLocalStorageItem } from 'modules/localStorage/hooks/useLocalStorageitem';

interface IReturn {
    options: LSOption[];
    addLocalStorageOption: (options: IAutocompleteOption[]) => void;
}

const LS_ITEM_NAME = 'prev_searched_locations';

export const usePreviouslySearchedLocations = (): IReturn => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const {
        get: getLocatStorageItem,
        set: setLocalStorageItem,
    } = useLocalStorageItem(LS_ITEM_NAME);

    const parseOptionsFromLS = useCallback((lsItem: string): LSOption[] => {
        let optionsLSParsed: LSOption[] = [];
        if (lsItem) {
            optionsLSParsed = JSON.parse(lsItem);
        }

        return optionsLSParsed;
    }, []);

    const validateDuplicity = (
        lsItemParsed: LSOption[],
        locations: IAutocompleteOption[],
    ): boolean => {
        let isDuplicity = false;

        lsItemParsed.forEach((option) => {
            const locationIdsJoined = locations.map((location) => location.id).join('-');

            if (locationIdsJoined === option.id) {
                return isDuplicity = true;
            }
        });

        return isDuplicity;
    };

    const addLocalStorageOption = useCallback((locations: IAutocompleteOption[]) => {
        const optionsParsed = parseOptionsFromLS(getLocatStorageItem());
        const options = [...optionsParsed];

        const isDuplicity = validateDuplicity(
            optionsParsed,
            locations,
        );

        if (isDuplicity) return;

        if (options.length >= 5) {
            options.pop();
        }

        options.unshift({
            id: locations.map((location) => location.id).join('-'),
            locationOptions: locations,
        });

        setLocalStorageItem(JSON.stringify(options));
    }, [parseOptionsFromLS, getLocatStorageItem, setLocalStorageItem]);

    const options = parseOptionsFromLS(getLocatStorageItem());

    return {
        addLocalStorageOption,
        options: isMobile
            ? options.slice(0, 3)
            : options,
    };
};
