import { ELocationType } from 'modules/api/clients/location/enums/type/ELocationType';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';

export const renameDistrictLocation = (
    (location: ILocation): ILocation => {
        const { type } = location;

        const isDistrict = type === ELocationType.DISTRICT;
        const districtName = isDistrict && location.name.replace('okres', '').trim();
        return {
            ...location,
            name: isDistrict
                ? `${districtName} (celý okres)`
                : location.name,
        };
    });