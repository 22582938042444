import { FC, useState, useEffect } from 'react';
import { Grid2, Box } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import EuroIcon from 'modules/theme/components/icons/basic/Euro.svg';
import {
    CommonInput
} from 'components/advertisementsFilter/modules/extended/components/common/input/CommonInput';

interface IPriceInputs {
    priceToMain: number;
    priceFromMain: number;
    onChangePriceToMain: (value: number) => void;
    onChangePriceFromMain: (value: number) => void;
}

export const PriceInputs: FC<IPriceInputs> = ({
    priceToMain,
    priceFromMain,
    onChangePriceToMain,
    onChangePriceFromMain,
}) => {
    const [priceFrom, setPriceFrom] = useState(priceFromMain);
    const [priceTo, setPriceTo] = useState(priceToMain);

    const onChangePriceFromMainDebounced = useDebouncedCallback(onChangePriceFromMain, 500);
    const onChangePriceToMainDebounced = useDebouncedCallback(onChangePriceToMain, 500);

    useEffect(() => {
        if (!priceFromMain) {
            setPriceFrom(0);
        }

        if (!priceToMain) {
            setPriceTo(0);
        }
    }, [priceFromMain, priceToMain]);

    const onChangePriceFrom = (value: number): void => {
        setPriceFrom(value);
        onChangePriceFromMainDebounced(value);
    };

    const onChangePriceTo = (value: number): void => {
        setPriceTo(value);
        onChangePriceToMainDebounced(value);
    };

    return <Grid2 container spacing={1}>
        <Grid2 size={{ xs: 5.5 }}>
            <CommonInput
                label='Cena od'
                inputType='number'
                value={priceFrom}
                endIcon={EuroIcon}
                onChange={onChangePriceFrom}
            />
        </Grid2>

        <Grid2
            size={{ xs: 1 }}
            display='flex'
            alignItems='center'
            justifyContent='center'
        >
            <Box
                color={(theme) => theme.palette.labelTertiary.main}
            >
                -
            </Box>
        </Grid2>

        <Grid2 size={{ xs: 5.5 }} alignItems='flex-end'>
            <CommonInput
                label='Cena do'
                value={priceTo}
                inputType='number'
                endIcon={EuroIcon}
                onChange={onChangePriceTo}
            />
        </Grid2>
    </Grid2>;
};
