import { FC, useEffect, useState } from 'react';
import { Grid2, Box } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import AreaIcon from 'modules/theme/components/icons/technical/Area.svg';
import { CommonInput } from 'components/advertisementsFilter/modules/extended/components/common/input/CommonInput';

interface IAreaInputs {
    areaToMain: number;
    areaFromMain: number;
    onChangeAreaToMain: (value: number) => void;
    onChangeAreaFromMain: (value: number) => void;
}

export const AreaInputs: FC<IAreaInputs> = ({
    areaToMain,
    areaFromMain,
    onChangeAreaToMain,
    onChangeAreaFromMain,
}) => {
    const [areaFrom, setAreaFrom] = useState(areaFromMain);
    const [areaTo, setAreaTo] = useState(areaToMain);

    const onChangeAreaFromMainDebounced = useDebouncedCallback(onChangeAreaFromMain, 500);
    const onChangeAreaToMainDebounced = useDebouncedCallback(onChangeAreaToMain, 500);

    useEffect(() => {
        if (!areaFromMain) {
            setAreaFrom(0);
        }

        if (!areaToMain) {
            setAreaTo(0);
        }
    }, [areaFromMain, areaToMain]);

    const onChangeAreaFrom = (value: number): void => {
        setAreaFrom(value);
        onChangeAreaFromMainDebounced(value);
    };

    const onChangeAreaTo = (value: number): void => {
        setAreaTo(value);
        onChangeAreaToMainDebounced(value);
    };

    return (
        <Grid2
            container
            spacing={1}
            sx={{
                '& .MuiInputBase-root': { height: 56 },
            }}
        >
            <Grid2 size={{ xs: 5.5 }}>
                <CommonInput
                    value={areaFrom}
                    label='Plocha od'
                    inputType='number'
                    endIcon={AreaIcon}
                    onChange={onChangeAreaFrom}
                />
            </Grid2>

            <Grid2
                size={{ xs: 1 }}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Box color={(theme) => theme.palette.labelTertiary.main}>-</Box>
            </Grid2>

            <Grid2 size={{ xs: 5.5 }} alignItems='flex-end'>
                <CommonInput
                    value={areaTo}
                    label='Plocha do'
                    inputType='number'
                    endIcon={AreaIcon}
                    onChange={onChangeAreaTo}
                />
            </Grid2>
        </Grid2>
    );
};
