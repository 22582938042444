import React, { FC, FunctionComponent, SVGProps } from 'react';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';

interface IRadioCardContent {
    label: string;
    noWrap?: boolean;
    iconWidth: number;
    noTitle?: boolean;
    isTextBold?: boolean;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const RadioCardContent: FC<IRadioCardContent> = ({
    icon,
    label,
    noWrap,
    noTitle,
    iconWidth,
    isTextBold,
}) => {

    return <>
        {
            icon && <Svg
                icon
                component={icon}
                width={iconWidth}
            />
        }
        {
            !noTitle &&
                <Text
                    noWrap={noWrap}
                    semibold={isTextBold}
                    variant='label1'
                >
                    {label}
                </Text>
        }
    </>;
};