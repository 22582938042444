import { useCallback, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { IAutocompleteOption } from 'components/advertisementsFilter/modules/location/interfaces/IAutocompleteOption';
import { fetchLocationSuggestions } from 'components/advertisementsFilter/modules/location/api/fetchLocationSuggestions';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { renameDistrictLocation } from 'components/advertisementsFilter/modules/location/helpers/renameLocation';
import { SuggestionsLimit } from 'components/advertisementsFilter/modules/location/constants/limit';
import { ELocationType } from 'modules/api/clients/location/enums/type/ELocationType';

export const useSearchFilterLocation = (
    initialLocations: ILocation[],
    locationsFromUrl?: ILocation[],
): void => {
    const locationQuery = useLocationStore((store) => store.locationQuery);
    const changeLocationQuery = useLocationStore((store) => store.changeLocationQuery);
    const setSelectedLocations = useLocationStore((store) => store.setSelectedLocations);
    const setAutocompleteOptions = useLocationStore((store) => store.setAutocompleteOptions);
    const setLocationOptionsInitial = useLocationStore((store) => store.setLocationOptionsInitial);

    useEffect(() => {
        setLocationOptionsInitial(initialLocations);
    }, [initialLocations, setLocationOptionsInitial]);

    const appendParentNameToSubName = useCallback(
        (location: ILocation): IAutocompleteOption => {
            const { parent, type } = location;
            const typesWithSubName = [ELocationType.CITY, ELocationType.CITY_PART];

            const hasSubName = typesWithSubName.includes(type);
            return !!hasSubName
                ? { ...location, subName: `${parent.name}` }
                : location;
        }, []);

    useEffect(() => {
        if (!locationsFromUrl || !locationsFromUrl.length) return;

        setSelectedLocations(locationsFromUrl);
    }, [locationsFromUrl, setSelectedLocations, changeLocationQuery]);

    const fetchLocations = useCallback(async (query: string) => {
        if (!locationQuery || locationQuery.length <= 1) return;

        try {
            const locations = await fetchLocationSuggestions(query, SuggestionsLimit);

            const locationsDistrictsRenamed = locations.map((location) =>
                renameDistrictLocation(location)
            );

            const locationsWithParentNames = locationsDistrictsRenamed.map((location) =>
                appendParentNameToSubName(location)
            );

            setAutocompleteOptions(locationsWithParentNames);
        } catch (error) {
            console.error(error);
        }
    }, [locationQuery, appendParentNameToSubName, setAutocompleteOptions]);

    const debouncedFetchLocations = useDebouncedCallback(fetchLocations, 250);

    useEffect(() => {
        (async () => {
            await debouncedFetchLocations(locationQuery);
        })();
    }, [locationQuery, debouncedFetchLocations]);
};