import { FC } from 'react';
import useSession from 'modules/stores/session/useSession';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import {
    MobileFilterWrapper
} from 'components/advertisementsFilter/components/mobile/listing/partials/wrapper/FilterWrapper';
import {
    MultiInput
} from 'components/advertisementsFilter/components/common/multiInput/MultiInput';
import {
    BrandIcon
} from 'components/advertisementsFilter/components/mobile/listing/partials/filterHeader/partials/brandIcon/BrandIcon';
import { ETransactionName } from 'components/advertisementsFilter/modules/transaction/enums/ETransactionName';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';
import { AddAdvertiser } from 'components/layout/header/components/navbar/common/links/addAdvertisement/mobile/AddAdvertiser';

interface IMobileFilterHeader {
    isFilterOpened: boolean;
    toggleFilter: (flag: boolean) => void;
    toggleExtendedFilter: (flag: boolean) => void;
}

export const MobileFilterHeader: FC<IMobileFilterHeader> = ({
    isFilterOpened,
    toggleExtendedFilter,
}) => {
    const session = useSession();
    const isLoggedIn = !!session;

    let href = isLoggedIn ? 'pridanie' : 'pridanie-neprihlaseny';
    href = `${AppConfigService.getNehnutelnostiUrl()}/${href}`;

    const selectedTransaction = useTransactionStore((store) => store.selectedTransaction);
    const selectedLocationNames = useLocationStore((store) => store.selectedLocationNames);
    const selectedCategoryNames = useCategoriesStore((store) => store.selectedCategoryNames);

    return <MobileFilterWrapper>
        <BrandIcon/>

        <MultiInput
            height={64}
            searchButtonSmall
            isWithSearchButton
            isWithExtendedFilterButton
            category={selectedCategoryNames}
            onSearchButtonClick={() => {
                toggleExtendedFilter(!isFilterOpened);
            }}
            location={selectedLocationNames}
            onExtendedButtonClick={() => toggleExtendedFilter(true)}
            transaction={selectedTransaction && ETransactionName[selectedTransaction.name]}
        />

        <AddAdvertiser href={href}/>
    </MobileFilterWrapper>;
};
