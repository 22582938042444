import { Box, Divider } from '@mui/material';
import { FC, useState } from 'react';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Input } from 'modules/theme/components/fields/input/Input';
import {
    Title
} from 'components/advertisementsFilter/modules/extended/components/title/Title';
import { Popover } from 'components/advertisementsFilter/modules/common/components/popover/Popover';
import {
    ContentItemWrapper
} from 'components/advertisementsFilter/modules/extended/components/common/contentItem/wrapper/ContentItemWrapper';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { Autocomplete } from 'components/advertisementsFilter/modules/extended/components/location/partials/autocomplete/Autocomplete';
import { usePreviouslySearchedLocations } from 'components/advertisementsFilter/modules/location/hooks/usePreviouslySearchedLocations';
import { usePopover } from 'components/advertisementsFilter/modules/common/hooks/popover/usePopover';
import PinIcon from 'modules/theme/components/icons/basic/MappinOutline.svg';
import {
    SelectedLocations
} from 'components/advertisementsFilter/modules/location/components/common/selectedLocations/SelectedLocations';
import {
    PreviouslySearched
} from 'components/advertisementsFilter/modules/extended/components/location/partials/previouslySearched/PreviouslySearched';
import { CompleteBox } from 'components/advertisementsFilter/modules/extended/components/location/partials/completeBox/CompleteBox';
import { LocationNotFound } from 'components/advertisementsFilter/modules/location/components/common/locationNotFound/LocationNotFound';

export const Location: FC = () => {
    const { options: previouslySearchedOptions } =
        usePreviouslySearchedLocations();


    const [inFocus, setInFocus] = useState(false);
    const locationQuery = useLocationStore((store) => store.locationQuery);
    const onSelectLocation = useLocationStore((store) => store.onSelectLocation);
    const changeLocationQuery = useLocationStore((store) => store.changeLocationQuery);
    const autocompleteOptions = useLocationStore((store) => store.autocompleteOptions);
    const setSelectedLocations = useLocationStore((store) => store.setSelectedLocations);
    const selectedLocationNames = useLocationStore((store) => store.selectedLocationNames);
    const locationOptionsInitial = useLocationStore((store) => store.locationOptionsInitial);

    const showNotFound = locationQuery && !autocompleteOptions?.length;
    const showSuggestions = locationQuery && locationQuery.length >= 2 && autocompleteOptions.length;
    const showLastSearched = !!previouslySearchedOptions.length && (!locationQuery || locationQuery.length === 1);
    const showLocationOptions = !previouslySearchedOptions.length && (!locationQuery || locationQuery.length === 1);
    const areSelectedLocations = !!selectedLocationNames.length;
    const showDivider = areSelectedLocations && showNotFound && !showLastSearched;

    const title = showSuggestions
        ? 'Vyberte lokalitu:'
        : showLastSearched
            ? 'Naposledy ste hľadali:'
            : showLocationOptions
                ? 'Zadajte mesto, obec, okres ...'
                : '';

    const options = showSuggestions
        ? autocompleteOptions
        : showLastSearched
            ? []
            : showLocationOptions
                ? locationOptionsInitial
                : [];

    const {
        anchorEl: anchorElLocation,
        onOpen: onOpenLocation,
        isOpen: isOpenLocation,
        onClose: onCloseLocation
    } = usePopover();

    const suggestionsComponent = (
        <Autocomplete
            title={title}
            maxHeight={250}
            options={options}
            onClick={(location) => {
                onSelectLocation(location);
                onCloseLocation();
            }}
        />
    );

    const selectLocationsHint = (
        <CompleteBox
            options={locationOptionsInitial}
            onChangeLocation={(value) => {
                onSelectLocation(value);
            }}
        />
    );

    const lastSearchedComponent = (
        <PreviouslySearched
            paddingTop={1}
            title='Naposledy ste hľadali:'
            options={previouslySearchedOptions}
            onClick={(values) => {
                setSelectedLocations(values);
                onCloseLocation();
            }}
        />
    );

    const notFoundComponent = <LocationNotFound />;

    const component = showLastSearched
        ? lastSearchedComponent
        : showSuggestions
            ? suggestionsComponent
            : showNotFound
                ? notFoundComponent
                : selectLocationsHint;

    return <ContentItemWrapper>
        <Box mb={1}>
            <Title text='Lokalita'/>
        </Box>

        <Box
            id='filter.locationInputExtended'
            onClick={(event) => onOpenLocation(event.currentTarget)}
            sx={{
                '& .MuiInputBase-root': { height: 56 },
                '& .MuiFormControl-root .MuiOutlinedInput-root': {
                    height: 56,
                }
            }}
        >
            <Input
                textEllipsis
                autoComplete='off'
                value={isOpenLocation
                    ? locationQuery
                    : selectedLocationNames
                }
                endIconStopPropagation
                startIcon={() =>
                    <Svg icon component={PinIcon} width={24}/>
                }
                placeholder='Mesto, obec ...'
                onChange={changeLocationQuery}
                onFocus={() => setInFocus(true)}
                onBlur={() => setInFocus(false)}
            />
        </Box>

        <Popover
            width={anchorElLocation?.clientWidth}
            isOpen={isOpenLocation}
            borderRadius='8px'
            onClose={() => {
                if (!inFocus) {
                    onCloseLocation();
                }
                if (locationQuery) {
                    changeLocationQuery('');
                }
            }}
            anchorEl={anchorElLocation}
            offsetOptions={{
                x: 0,
                y: 8,
            }}
        >
            <Box
                minWidth={300}
                maxHeight={608}
                border={(theme) => `1px solid ${theme.palette.backgroundPrimary.main}`}
                sx={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                {areSelectedLocations &&
                    <Box mx={1} mt={2} mb={1}>
                        <SelectedLocations
                            isExtended
                            locationQuery={locationQuery}
                            previouslySearched={previouslySearchedOptions}
                        />
                    </Box>
                }

                {
                    showDivider &&
                    <Divider sx={{ mt: 2, mx: 4, borderColor: (theme) => theme.palette.identityLightGray.main }} />
                }

                {component}
            </Box>
        </Popover>
    </ContentItemWrapper>;
};