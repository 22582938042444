import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';

export const LocationNotFound: FC = () => {
    return (
        <Box p={2}>
            <Box maxWidth={400} mx='auto'>
                <Text
                    center
                    semibold
                    variant='label1'
                    color={(palette) => palette.labelSecondary}
                >
                    Hľadanú lokalitu sa nám nepodarilo nájsť.
                    Upravte,&nbsp;prosím, hľadaný výraz.
                </Text>
            </Box>
        </Box>
    );
};
