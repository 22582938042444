import { LocationResponseDto, LocationDto } from 'modules/api/generated';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { ELocationType } from 'modules/api/clients/location/enums/type/ELocationType';

export const mapLocationResponseDtoToILocation = (
    dto: LocationResponseDto | LocationDto,
): ILocation => ({
    id: dto.id,
    name: dto.name,
    sefName: dto.sefName,
    type: ELocationType[dto.type],
    ...(!!dto.parent && ({ parent: mapLocationResponseDtoToILocation(dto.parent) })),
});